<template>
    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
            <span class="danger-total__table-value">{{format(restaurantEventValue)}}</span>
            <difference class="danger-total__table-value"
                v-if="compareRestaurantEventValue"
                :primaryValue="restaurantEventValue"
                :compareValue="compareRestaurantEventValue"
                mode="percentage"
                :good="differenceGood"
                filter="percentFloatFormat"
            />
        </span>
    </td>
</template>

<script>
    import Difference from "@/components/Difference";

    export default {
        name: "CompareRestaurantPrimaryCell",
        components: {
            Difference
        },
        props: {
            restaurant: {
                type: Object,
                required: true
            },
            eventId: {
                type: String,
                required: true
            },
            eventField: {
                type: String,
                required: true
            },
            filter: {
                type: String,
                required: true
            },
            compareDanger: {
                type: Object,
                required: true
            },
            differenceGood: {
                type: String,
                default: "negative"
            },
        },
        computed: {
            restaurantEventValue() {
                return this.restaurant?.events?.find(({ id }) => id === this.eventId)?.[this.eventField] ?? 0;
            },
            compareRestaurantEventValue() {
                return this.compareDanger?.restaurants?.find(({ id }) => id === this.restaurant.id)?.events?.find(({ id }) => id === this.eventId)?.[this.eventField];
            },
        },
        methods: {
            format(value) {
                return this.$options.filters?.[this.filter](value);
            }
        }
    }
</script>

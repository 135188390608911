<template>
    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
            <span class="danger-total__table-value">{{format(restaurantEventValue)}}</span>
            <popper class="danger-total__table-value danger-total__table-value_tooltip"
                v-if="totalRestaurantEventsFieldValue"
                trigger="hover"
                :options="popperOptions"
            >
                <span class="popper danger-total__tooltip">{{totalRestaurantEventsTooltip}}</span>
                <percentage slot="reference" :part="restaurantEventValue" :whole="totalRestaurantEventsFieldValue" />
            </popper>
        </span>
    </td>
</template>

<script>
    import { mapGetters } from "vuex";
    import Percentage from "@/components/Percentage";
    import Difference from "@/components/Difference";

    export default {
        name: "BaseRestaurantCell",
        components: {
            Percentage,
            Difference,
        },
        props: {
            restaurantEvents: {
                type: Array,
                required: true
            },
            eventId: {
                type: String,
                required: true
            },
            eventField: {
                type: String,
                required: true
            },
            filter: {
                type: String,
                required: true
            },
            totalRestaurantEventsFieldValue: {},
            totalRestaurantEventsTooltip: {},
            popperOptions: {}
        },
        computed: {
            ...mapGetters([
                "userRestaurants",
            ]),
            restaurantEventValue() {
                return this.restaurantEvents?.find(({ id }) => id === this.eventId)?.[this.eventField] ?? 0;
            },
        },
        methods: {
            format(value) {
                return this.$options.filters?.[this.filter](value);
            }
        }
    }
</script>

<template>
    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
        <span class="danger-total__table-value">{{format(compareRestaurantEventValue)}}</span>
    </td>
</template>

<script>
    export default {
        name: "CompareRestaurantCompareCell",
        props: {
            compareDanger: {
                type: Object,
                required: true
            },
            restaurantId: {
                type: String,
                required: true
            },
            eventId: {
                type: String,
                required: true
            },
            eventField: {
                type: String,
                required: true
            },
            filter: {
                type: String,
                required: true
            },
        },
        computed: {
            compareRestaurantEventValue() {
                return this.compareDanger?.restaurants?.find(({ id }) => id === this.restaurantId)?.events?.find(({ id }) => id === this.eventId)?.[this.eventField] ?? 0;
            },
        },
        methods: {
            format(value) {
                return this.$options.filters?.[this.filter](value);
            }
        }
    }
</script>

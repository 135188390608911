<template>
    <div class="danger-total">
        <section class="danger-total__settings">
            <h2 class="sr-only">Настройки отчета "Опасные операции"</h2>
            <form class="danger-total__controls" @submit.prevent>
                <div class="danger-total__dateranges">
                    <daterange-picker class="danger-total__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                        :minDate="minDate"
                    >
                        Период отчета:
                    </daterange-picker>
                    <daterange-picker class="danger-total__daterange"
                        v-if="modeId === 'compareOtherPeriod'"
                        v-model="compareDaterangeModel"
                        name="compareOtherPeriod"
                        :minDate="minDate"
                    >
                        Период сравнения:
                    </daterange-picker>
                </div>
                <div class="danger-total__toggles">
                    <toggle-button class="danger-total__toggle-input"
                        :color="toggleColors"
                        v-model="modeModel"
                        :width="130"
                        :labels="modeToggleLabels"
                    />
                </div>
                <danger-settings class="danger-total__settings"  v-if="isShowRestaurantsFilter" />
            </form>
        </section>
        <spinner class="danger-total__spinner" v-if="status === 'loading'" />
        <error-message class="danger-total__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Опасные операции".
        </error-message>
        <section class="danger-total__data" v-else-if="status === 'success'">
            <div class="danger-total__base"
                v-if="modeId === 'base'"
            >
                <div class="danger-total__box box">
                    <table class="danger-total__table danger-total__table_striped danger-total__table_hover">
                        <col class="danger-total__table-column danger-total__table-column_title">
                        <template v-for="event in reportTotalEvents">
                            <col class="danger-total__table-column danger-total__table-column_danger_count"
                                :key="`${event.id}-danger_count`"
                            >
                            <col class="danger-total__table-column danger-total__table-column_sum"
                                :key="`${event.id}-sum`"
                            >
                            <col class="danger-total__table-column danger-total__table-column_danger_count"
                                :key="`${event.id}-dangers_per_1000_orders`"
                            >
                        </template>
                        <col class="danger-total__table-column danger-total__table-column_danger_count">
                        <col class="danger-total__table-column danger-total__table-column_sum">
                        <col class="danger-total__table-column danger-total__table-column_dangers_per_1000_orders">
                        <thead>
                            <tr class="danger-total__table-row danger-total__table-row_head">
                                <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_sortable danger-total__table-cell_vat"
                                    scope="col"
                                    rowspan="2"
                                    @click="setSorting('total', 'title')"
                                >
                                    <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_left">
                                        <span class="danger-total__table-text">Источник</span>
                                        <svg-icon class="danger-total__table-icon"
                                            v-if="sorting.group === 'total' && sorting.field === 'title'"
                                            :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                        />
                                    </span>
                                </th>
                                <th v-for="event in reportTotalEvents"
                                    :key="event.id"
                                    class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat"
                                    scope="col"
                                    colspan="3"
                                >
                                    {{event.event}}
                                </th>
                                <th class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat" scope="col" colspan="4">Итого</th>
                            </tr>
                            <tr class="danger-total__table-row danger-total__table-row_head">
                                <template v-for="event in reportTotalEvents">
                                    <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom danger-total__table-cell_offset-left"
                                        :key="`${event.id}-danger_count`"
                                        scope="col"
                                        @click="setSorting(event.id, 'danger_count')"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                            <span class="danger-total__table-text">Общее количество</span>
                                            <svg-icon class="danger-total__table-icon"
                                                v-if="sorting.group === event.id && sorting.field === 'danger_count'"
                                                :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                            />
                                        </span>
                                    </th>
                                    <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom"
                                        :key="`${event.id}-sum`"
                                        scope="col"
                                        @click="setSorting(event.id, 'sum')"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                            <span class="danger-total__table-text">Сумма</span>
                                            <svg-icon class="danger-total__table-icon"
                                                v-if="sorting.group === event.id && sorting.field === 'sum'"
                                                :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                            />
                                        </span>
                                    </th>
                                    <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom danger-total__table-cell_offset-right"
                                        :key="`${event.id}-dangers_per_1000_orders`"
                                        scope="col"
                                        @click="setSorting(event.id, 'dangers_per_1000_orders')"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                            <span class="danger-total__table-text">Количество на&nbsp;1000&nbsp;заказов</span>
                                            <svg-icon class="danger-total__table-icon"
                                                v-if="sorting.group === event.id && sorting.field === 'dangers_per_1000_orders'"
                                                :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                            />
                                        </span>
                                    </th>
                                </template>
                                <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom danger-total__table-cell_offset-left"
                                    scope="col"
                                    @click="setSorting('total', 'danger_count')"
                                >
                                    <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                        <span class="danger-total__table-text">Общее количество</span>
                                        <svg-icon class="danger-total__table-icon"
                                            v-if="sorting.group === 'total' && sorting.field === 'danger_count'"
                                            :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                        />
                                    </span>
                                </th>
                                <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom"
                                    scope="col"
                                    @click="setSorting('total', 'sum')"
                                >
                                    <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                        <span class="danger-total__table-text">Сумма</span>
                                        <svg-icon class="danger-total__table-icon"
                                            v-if="sorting.group === 'total' && sorting.field === 'sum'"
                                            :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                        />
                                    </span>
                                </th>
                                <th class="danger-total__table-cell danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_sortable danger-total__table-cell_head-bottom danger-total__table-cell_offset-right"
                                    scope="col"
                                    @click="setSorting('total', 'dangers_per_1000_orders')"
                                >
                                    <span class="danger-total__cell-content danger-total__cell-content_columns danger-total__cell-content_right">
                                        <span class="danger-total__table-text">Количество на&nbsp;1000&nbsp;заказов</span>
                                        <svg-icon class="danger-total__table-icon"
                                            v-if="sorting.group === 'total' && sorting.field === 'dangers_per_1000_orders'"
                                            :icon="sorting.direction === 'ASC' ? 'chevron-up' : 'chevron-down'"
                                        />
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="danger-total__table-row danger-total__table-row_body danger-total__table-row_pinned"
                                v-if="reportRestaurantThis.length > 1"
                            >
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_title danger-total__table-cell_vat">
                                    <router-link class="link link_red"
                                        :to="{ name: 'DangerRestaurant', params: { id: 'total' } }"
                                        title="Смотреть общий отчет по опасным операциям"
                                    >
                                        Итого
                                    </router-link>
                                </td>
                                <template v-for="event in reportTotal.events">
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left"
                                        :key="`${event.id}-danger_count`"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">{{event.danger_count | intFormat}}</span>
                                            <popper class="danger-total__table-value danger-total__table-value_tooltip"
                                                trigger="hover"
                                                :options="popperOptions"
                                            >
                                                <span class="popper danger-total__tooltip">Доля опасных операций типа "{{event.event}}" всех ресторанов в опасных операциях всех типов всех ресторанов за выбранный период отчёта</span>
                                                <percentage slot="reference" :part="event.danger_count" :whole="reportTotal.danger_count" />
                                            </popper>
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat"
                                        :key="`${event.id}-sum`"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">{{event.sum | currencyIntFormat}}</span>
                                            <popper class="danger-total__table-value danger-total__table-value_tooltip"
                                                trigger="hover"
                                                :options="popperOptions"
                                            >
                                                <span class="popper danger-total__tooltip">Доля суммы опасных операций типа "{{event.event}}" всех ресторанов в сумме опасных операций всех типов всех ресторанов за выбранный период отчёта</span>
                                                <percentage slot="reference" :part="event.sum" :whole="reportTotal.sum" />
                                            </popper>
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right"
                                        :key="`${event.id}-dangers_per_1000_orders`"
                                    >
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">{{event.dangers_per_1000_orders | floatFormat}}</span>
                                            <popper class="danger-total__table-value danger-total__table-value_tooltip"
                                                trigger="hover"
                                                :options="popperOptions"
                                            >
                                                <span class="popper danger-total__tooltip">Доля опасных операций типа "{{event.event}}" на 1000 заказов всех ресторанов в опасных операциях всех типов на 1000 заказов всех ресторанов за выбранный период отчёта</span>
                                                <percentage slot="reference" :part="event.dangers_per_1000_orders" :whole="reportTotal.dangers_per_1000_orders" />
                                            </popper>
                                        </span>
                                    </td>
                                </template>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left">
                                    <span class="danger-total__table-value">{{reportTotal.danger_count | intFormat}}</span>
                                </td>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat">
                                    <span class="danger-total__table-value">{{reportTotal.sum | currencyIntFormat}}</span>
                                </td>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right">
                                    <span class="danger-total__table-value">{{reportTotal.dangers_per_1000_orders | floatFormat}}</span>
                                </td>
                            </tr>
                            <tr class="danger-total__table-row danger-total__table-row_body"
                                v-for="restaurant in reportRestaurantThis"
                                :key="restaurant.id"
                            >
                                <td class="danger-total__table-cell danger-total__table-cell_title danger-total__table-cell_vat">
                                    <router-link class="link link_red"
                                        :to="{ name: 'DangerRestaurant', params: { id: restaurant.id } }"
                                        :title="`Смотреть опасные операции ресторана ${restaurant.title}`">
                                        {{restaurant.title}}
                                    </router-link>
                                </td>
                                <template v-for="event in reportTotalEvents">
                                    <base-restaurant-cell class="danger-total__table-cell_offset-left danger-total__table-cell_vat"
                                        :key="`${event.id}-danger_count`"
                                        :restaurantEvents="restaurant.events"
                                        :eventId="event.id"
                                        eventField="danger_count"
                                        filter="intFormat"
                                        :totalRestaurantEventsFieldValue="restaurant.danger_count"
                                        :totalRestaurantEventsTooltip='`Доля опасных операций типа "${event.event}" ресторана "${restaurant.title}" в опасных операциях всех типов этого ресторана за выбранный период отчёта`'
                                        :popperOptions="popperOptions"
                                    />
                                    <base-restaurant-cell class="danger-total__table-cell_vat"
                                        :key="`${event.id}-sum`"
                                        :eventId="event.id"
                                        :restaurantEvents="restaurant.events"
                                        eventField="sum"
                                        filter="currencyIntFormat"
                                        :totalRestaurantEventsFieldValue="restaurant.sum"
                                        :totalRestaurantEventsTooltip='`Доля суммы опасных операций типа "${event.event}" ресторана "${restaurant.title}" в сумме опасных операций всех типов этого ресторана за выбранный период отчёта`'
                                        :popperOptions="popperOptions"
                                    />
                                    <base-restaurant-cell class="danger-total__table-cell_vat danger-total__table-cell_offset-right"
                                        :key="`${event.id}-dangers_per_1000_orders`"
                                        :eventId="event.id"
                                        :restaurantEvents="restaurant.events"
                                        eventField="dangers_per_1000_orders"
                                        filter="floatFormat"
                                        :totalRestaurantEventsFieldValue="restaurant.dangers_per_1000_orders"
                                        :totalRestaurantEventsTooltip='`Доля опасных операций типа "${event.event}" на 1000 заказов ресторана "${restaurant.title}" в опасных операциях всех типов на 1000 заказов этого ресторана за выбранный период отчёта`'
                                        :popperOptions="popperOptions"
                                    />
                                </template>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left">
                                    <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                        <span class="danger-total__table-value">{{restaurant.danger_count | intFormat}}</span>
                                    </span>
                                </td>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat">
                                    <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                        <span class="danger-total__table-value">{{restaurant.sum | currencyIntFormat}}</span>
                                    </span>
                                </td>
                                <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right">
                                    <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                        <span class="danger-total__table-value">{{restaurant.dangers_per_1000_orders | floatFormat}}</span>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="danger-total__compare"
                v-else-if="modeId === 'compareOtherPeriod'"
            >
                <p class="danger-total__error" v-if="!compareDanger">Выберите период сравнения.</p>
                <template v-else>
                    <div class="danger-total__box box" v-if="reportRestaurantThis.length > 1">
                        <table class="danger-total__table danger-total__table_striped danger-total__table_hover">
                            <caption class="danger-total__table-caption">
                                <router-link class="link link_red"
                                    :to="{ name: 'DangerRestaurant', params: { id: 'total' } }"
                                    title="Смотреть общую эффективность по всем ресторанам"
                                >
                                    Итого
                                </router-link>
                            </caption>
                            <col class="danger-total__table-column danger-total__table-column_title">
                            <template v-for="event in reportAndCompareTotalEvents">
                                <col class="danger-total__table-column danger-total__table-column_danger_count"
                                    :key="`${event.id}-danger_count`"
                                >
                                <col class="danger-total__table-column danger-total__table-column_sum"
                                    :key="`${event.id}-sum`"
                                >
                                <col class="danger-total__table-column danger-total__table-column_dangers_per_1000_orders"
                                    :key="`${event.id}-dangers_per_1000_orders`"
                                >
                            </template>
                            <col class="danger-total__table-column danger-total__table-column_danger_count">
                            <col class="danger-total__table-column danger-total__table-column_sum">
                            <col class="danger-total__table-column danger-total__table-column_dangers_per_1000_orders">
                            <thead>
                                <tr class="danger-total__table-row danger-total__table-row_head">
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_vat" scope="col" rowspan="2">Источник</th>
                                    <th v-for="event in reportAndCompareTotalEvents"
                                        :key="event.id"
                                        class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat"
                                        scope="col"
                                        colspan="3"
                                    >
                                        {{event.title}}
                                    </th>
                                    <th class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat" scope="col" colspan="4">Итого</th>
                                </tr>
                                <tr class="danger-total__table-row danger-total__table-row_head">
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                            scope="col"
                                        >
                                            Общее количество
                                        </th>
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat"
                                            scope="col"
                                            :key="`${event.id}-danger_sum`"
                                        >
                                            Сумма
                                        </th>
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right"
                                            scope="col"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                        >
                                            Количество на&nbsp;1000&nbsp;заказов
                                        </th>
                                    </template>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_offset-left" scope="col">Количество</th>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar" scope="col">Сумма</th>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_offset-right" scope="col">Количество на&nbsp;1000&nbsp;заказов</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="danger-total__table-row danger-total__table-row_body">
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_title">
                                        {{reportDaterange | daterangeText}}
                                    </td>
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                        >
                                            <span v-if="findEventValue(reportTotalEvents, event.id, 'danger_count')"
                                                class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right"
                                            >
                                                <span class="danger-total__table-value">
                                                    {{findEventValue(reportTotalEvents, event.id, 'danger_count') | intFormat}}
                                                </span>
                                                <difference class="danger-total__table-value"
                                                    v-if="findEventValue(reportTotalEvents, event.id, 'danger_count') && findEventValue(compareTotalEvents, event.id, 'danger_count')"
                                                    :primaryValue="findEventValue(reportTotalEvents, event.id, 'danger_count')"
                                                    :compareValue="findEventValue(compareTotalEvents, event.id, 'danger_count')"
                                                    mode="percentage"
                                                    good="negative"
                                                    filter="percentFloatFormat"
                                                />
                                            </span>
                                            <span class="danger-total__table-value" v-else>0</span>
                                        </td>
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar"
                                            :key="`${event.id}-danger_sum`"
                                        >
                                            <span v-if="findEventValue(reportTotalEvents, event.id, 'sum')"
                                                class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right"
                                            >
                                                <span class="danger-total__table-value">
                                                    {{findEventValue(reportTotalEvents, event.id, 'sum') | currencyIntFormat}}
                                                </span>
                                                <difference class="danger-total__table-value"
                                                    v-if="findEventValue(reportTotalEvents, event.id, 'sum') && findEventValue(compareTotalEvents, event.id, 'sum')"
                                                    :primaryValue="findEventValue(reportTotalEvents, event.id, 'sum')"
                                                    :compareValue="findEventValue(compareTotalEvents, event.id, 'sum')"
                                                    mode="percentage"
                                                    good="negative"
                                                    filter="percentFloatFormat"
                                                />
                                            </span>
                                            <span class="danger-total__table-value" v-else>0</span>
                                        </td>
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                        >
                                            <span v-if="findEventValue(reportTotalEvents, event.id, 'dangers_per_1000_orders')"
                                                class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right"
                                            >
                                                <span class="danger-total__table-value">
                                                    {{findEventValue(reportTotalEvents, event.id, 'dangers_per_1000_orders') | floatFormat}}
                                                </span>
                                                <difference class="danger-total__table-value"
                                                    v-if="findEventValue(reportTotalEvents, event.id, 'dangers_per_1000_orders') && findEventValue(compareTotalEvents, event.id, 'dangers_per_1000_orders')"
                                                    :primaryValue="findEventValue(reportTotalEvents, event.id, 'dangers_per_1000_orders')"
                                                    :compareValue="findEventValue(compareTotalEvents, event.id, 'dangers_per_1000_orders')"
                                                    mode="percentage"
                                                    good="negative"
                                                    filter="percentFloatFormat"
                                                />
                                            </span>
                                            <span class="danger-total__table-value" v-else>0</span>
                                        </td>
                                    </template>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left">
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">
                                                {{reportTotal.danger_count | intFormat}}
                                            </span>
                                            <difference class="danger-total__table-value"
                                                v-if="compareTotal.danger_count !== 0"
                                                :primaryValue="reportTotal.danger_count"
                                                :compareValue="compareTotal.danger_count"
                                                mode="percentage"
                                                good="negative"
                                                filter="percentFloatFormat"
                                            />
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">
                                                {{reportTotal.sum | currencyIntFormat}}
                                            </span>
                                            <difference class="danger-total__table-value"
                                                v-if="compareTotal.sum !== 0"
                                                :primaryValue="reportTotal.sum"
                                                :compareValue="compareTotal.sum"
                                                mode="percentage"
                                                good="negative"
                                                filter="percentFloatFormat"
                                            />
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right">
                                        <span class="danger-total__cell-content danger-total__cell-content_rows danger-total__cell-content_right">
                                            <span class="danger-total__table-value">
                                                {{reportTotal.dangers_per_1000_orders | floatFormat}}
                                            </span>
                                            <difference class="danger-total__table-value"
                                                v-if="compareTotal.dangers_per_1000_orders !== 0"
                                                :primaryValue="reportTotal.dangers_per_1000_orders"
                                                :compareValue="compareTotal.dangers_per_1000_orders"
                                                mode="percentage"
                                                good="negative"
                                                filter="percentFloatFormat"
                                            />
                                        </span>
                                    </td>
                                </tr>
                                <tr class="danger-total__table-row danger-total__table-row_body">
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_title">
                                        {{compareDaterange | daterangeText}}
                                    </td>
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                        >
                                            <span class="danger-total__table-value">
                                                <template v-if="findEventValue(compareTotalEvents, event.id, 'danger_count')">
                                                    {{findEventValue(compareTotalEvents, event.id, 'danger_count') | intFormat}}
                                                </template>
                                                <template v-else>0</template>
                                            </span>
                                        </td>
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar"
                                            :key="`${event.id}-danger_sum`"
                                        >
                                            <span class="danger-total__table-value">
                                                <template v-if="findEventValue(compareTotalEvents, event.id, 'sum')">
                                                    {{findEventValue(compareTotalEvents, event.id, 'sum') | currencyIntFormat}}
                                                </template>
                                                <template v-else>0</template>
                                            </span>
                                        </td>
                                        <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                        >
                                            <span class="danger-total__table-value">
                                                <template v-if="findEventValue(compareTotalEvents, event.id, 'dangers_per_1000_orders')">
                                                    {{findEventValue(compareTotalEvents, event.id, 'dangers_per_1000_orders') | floatFormat}}
                                                </template>
                                                <template v-else>0</template>
                                            </span>
                                        </td>
                                    </template>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left">
                                        <span class="danger-total__table-value">
                                            {{compareTotal.danger_count | intFormat}}
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
                                        <span class="danger-total__table-value">
                                            {{compareTotal.sum | currencyIntFormat}}
                                        </span>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right">
                                        <span class="danger-total__table-value">
                                            {{compareTotal.dangers_per_1000_orders | floatFormat}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="danger-total__box box"
                        v-for="restaurant in reportRestaurantThis"
                        :key="restaurant.id"
                    >
                        <table class="danger-total__table danger-total__table_striped danger-total__table_hover">
                            <caption class="danger-total__table-caption">
                                <router-link class="link link_red"
                                    :to="{ name: 'DangerRestaurant', params: { id: restaurant.id } }"
                                    :title="`Смотреть опасные операции по ресторану ${restaurant.title}`">
                                    {{restaurant.title}}
                                </router-link>
                            </caption>
                            <col class="danger-total__table-column danger-total__table-column_title">
                            <template v-for="event in reportAndCompareTotalEvents">
                                <col class="danger-total__table-column danger-total__table-column_danger_count"
                                    :key="`${event.id}-danger_count`"
                                >
                                <col class="danger-total__table-column danger-total__table-column_sum"
                                    :key="`${event.id}-sum`"
                                >
                                <col class="danger-total__table-column danger-total__table-column_dangers_per_1000_orders"
                                    :key="`${event.id}-dangers_per_1000_orders`"
                                >
                            </template>
                            <col class="danger-total__table-column danger-total__table-column_danger_count">
                            <col class="danger-total__table-column danger-total__table-column_sum">
                            <col class="danger-total__table-column danger-total__table-column_dangers_per_1000_orders">
                            <thead>
                                <tr class="danger-total__table-row danger-total__table-row_head">
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_vat" scope="col" rowspan="2">Источник</th>
                                    <th v-for="event in reportAndCompareTotalEvents"
                                        :key="event.id"
                                        class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat"
                                        scope="col"
                                        colspan="3"
                                    >
                                        {{event.title}}
                                    </th>
                                    <th class="danger-total__table-cell danger-total__table-cell_tac danger-total__table-cell_head danger-total__table-cell_vat" scope="col" colspan="4">Итого</th>
                                </tr>
                                <tr class="danger-total__table-row danger-total__table-row_head">
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                            scope="col"
                                        >
                                            Общее количество
                                        </th>
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat"
                                            scope="col"
                                            :key="`${event.id}-danger_sum`"
                                        >
                                            Сумма
                                        </th>
                                        <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right"
                                            scope="col"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                        >
                                            Количество на&nbsp;1000&nbsp;заказов
                                        </th>
                                    </template>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-left" scope="col">Количество</th>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat" scope="col">Сумма</th>
                                    <th class="danger-total__table-cell danger-total__table-cell_head danger-total__table-cell_head-bottom danger-total__table-cell_tar danger-total__table-cell_vat danger-total__table-cell_offset-right" scope="col">Количество на&nbsp;1000&nbsp;заказов</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="danger-total__table-row danger-total__table-row_body">
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_title">
                                        {{reportDaterange | daterangeText}}
                                    </td>
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <compare-restaurant-primary-cell
                                            class="danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                            :restaurant="restaurant"
                                            :eventId="event.id"
                                            eventField="danger_count"
                                            filter="intFormat"
                                            :compareDanger="compareDanger"
                                        />
                                        <compare-restaurant-primary-cell
                                            :key="`${event.id}-sum`"
                                            :compareDanger="compareDanger"
                                            eventField="sum"
                                            filter="currencyIntFormat"
                                            :restaurant="restaurant"
                                            :eventId="event.id"
                                        />
                                        <compare-restaurant-primary-cell
                                            class="danger-total__table-cell_offset-right"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                            :compareDanger="compareDanger"
                                            eventField="dangers_per_1000_orders"
                                            filter="floatFormat"
                                            :restaurant="restaurant"
                                            :eventId="event.id"
                                        />
                                    </template>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left">
                                        <span class="danger-total__table-value">{{restaurant.danger_count | intFormat}}</span>
                                        <difference class="danger-total__table-value"
                                            v-if="findRestaurant(compareDanger, restaurant.id) && findRestaurant(compareDanger, restaurant.id).danger_count !== 0"
                                            :primaryValue="restaurant.danger_count"
                                            :compareValue="findRestaurant(compareDanger, restaurant.id).danger_count"
                                            mode="percentage"
                                            good="negative"
                                            filter="percentFloatFormat"
                                        />
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
                                        <span class="danger-total__table-value">{{restaurant.sum | currencyIntFormat}}</span>
                                        <difference class="danger-total__table-value"
                                            v-if="findRestaurant(compareDanger, restaurant.id) && findRestaurant(compareDanger, restaurant.id).sum !== 0"
                                            :primaryValue="restaurant.sum"
                                            :compareValue="findRestaurant(compareDanger, restaurant.id).sum"
                                            mode="percentage"
                                            good="negative"
                                            filter="percentFloatFormat"
                                        />
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right">
                                        <span class="danger-total__table-value">{{restaurant.dangers_per_1000_orders | floatFormat}}</span>
                                        <difference class="danger-total__table-value"
                                            v-if="findRestaurant(compareDanger, restaurant.id) && findRestaurant(compareDanger, restaurant.id).dangers_per_1000_orders !== 0"
                                            :primaryValue="restaurant.dangers_per_1000_orders"
                                            :compareValue="findRestaurant(compareDanger, restaurant.id).dangers_per_1000_orders"
                                            mode="percentage"
                                            good="negative"
                                            filter="percentFloatFormat"
                                        />
                                    </td>
                                </tr>
                                <tr class="danger-total__table-row danger-total__table-row_body">
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_title">
                                        {{compareDaterange | daterangeText}}
                                    </td>
                                    <template v-for="event in reportAndCompareTotalEvents">
                                        <compare-restaurant-compare-cell
                                            class="danger-total__table-cell_offset-left"
                                            :key="`${event.id}-danger_count`"
                                            :compareDanger="compareDanger"
                                            :restaurantId="restaurant.id"
                                            :eventId="event.id"
                                            eventField="danger_count"
                                            filter="intFormat"
                                        />
                                        <compare-restaurant-compare-cell
                                            :key="`${event.id}-sum`"
                                            :compareDanger="compareDanger"
                                            :restaurantId="restaurant.id"
                                            :eventId="event.id"
                                            eventField="sum"
                                            filter="currencyIntFormat"
                                        />
                                        <compare-restaurant-compare-cell
                                            class="danger-total__table-cell_offset-right"
                                            :key="`${event.id}-dangers_per_1000_orders`"
                                            :compareDanger="compareDanger"
                                            :restaurantId="restaurant.id"
                                            :eventId="event.id"
                                            eventField="dangers_per_1000_orders"
                                            filter="floatFormat"
                                        />
                                    </template>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-left">
                                        <template v-if="findRestaurant(compareDanger, restaurant.id)">
                                            {{findRestaurant(compareDanger, restaurant.id).danger_count | intFormat}}
                                        </template>
                                        <template v-else>0</template>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar">
                                        <template v-if="findRestaurant(compareDanger, restaurant.id)">
                                            {{findRestaurant(compareDanger, restaurant.id).sum  | currencyIntFormat}}
                                        </template>
                                        <template v-else>0</template>
                                    </td>
                                    <td class="danger-total__table-cell danger-total__table-cell_body danger-total__table-cell_tar danger-total__table-cell_offset-right">
                                        <template v-if="findRestaurant(compareDanger, restaurant.id)">
                                            {{findRestaurant(compareDanger, restaurant.id).dangers_per_1000_orders | floatFormat}}
                                        </template>
                                        <template v-else>0</template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import { daterangeKey, isDaterangeSet, daterangeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import DaterangePicker from "@/components/DaterangePicker";
    import BaseRestaurantCell from "./danger-total/BaseRestaurantCell";
    import CompareRestaurantPrimaryCell from "./danger-total/CompareRestaurantPrimaryCell";
    import CompareRestaurantCompareCell from "./danger-total/CompareRestaurantCompareCell";
    import Difference from "@/components/Difference";
    import Percentage from "@/components/Percentage";
    import DangerSettings from "./common/DangerSettings";

    export default{
        name: "DangerTotal",
        components: {
            DangerSettings,
            DaterangePicker,
            BaseRestaurantCell,
            CompareRestaurantPrimaryCell,
            CompareRestaurantCompareCell,
            Difference,
            Percentage,
        },
        props: {
            reportDaterange: {
                type: Object,
                required: true
            },
            reportRestaurants: {
                required: true
            },
            compareDaterange: {
                required: true
            },
            userRestaurants: {
                required: true
            },
            reportRestaurantsKey: {
                required: true
            },
            reportKey: {
                required: true
            },
            minDate: {
                type: Date,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                modeId: state => state.danger.modeId,
                sorting: state => state.danger.sorting.restaurants,
                data: state => state.danger.data
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(value) {
                    this.setDangerParameter({ parameter: "compareDaterange", value });
                }
            },
            modeModel: {
                get() {
                    return this.modeId === "compareOtherPeriod";
                },
                set(isCompareOtherPeriod) {
                    this.setDangerParameter({
                        parameter: "modeId",
                        value: isCompareOtherPeriod ? "compareOtherPeriod" : "base"
                    });
                }
            },
            modeToggleLabels() {
                return {
                    unchecked: "Данные периода",
                    checked: "Сравнение периодов"
                };
            },
            toggleColors() {
                return {
                    unchecked: "#e64d53",
                    checked: "#e0121a"
                };
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }
                return dateranges;
            },
            requiredDangers() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`]);
            },
            reportDanger() {
                return this.data[this.reportKey];
            },
            reportRestaurantThis() {
                return this.reportDanger?.restaurants?.sort((restaurant1, restaurant2) => {
                    if (this.sorting.group === "total") {
                        if (this.sorting.field === "title") {
                            return compareStrings(
                                restaurant1.title,
                                restaurant2.title,
                                this.sorting.direction
                            );
                        } else {
                            return compareNumbers(
                                restaurant1[this.sorting.field],
                                restaurant2[this.sorting.field],
                                this.sorting.direction
                            );
                        }
                    } else {
                        let restaurant1Event = restaurant1?.events?.find(({ id }) => id === this.sorting.group);
                        let restaurant2Event = restaurant2?.events?.find(({ id }) => id === this.sorting.group);

                        return compareNumbers(
                            restaurant1Event?.[this.sorting.field],
                            restaurant2Event?.[this.sorting.field],
                            this.sorting.direction
                        );
                    }
                });
            },
            reportTotal() {
                return this.reportDanger?.total;
            },
            reportTotalEvents() {
                return this.reportDanger?.total?.events;
            },
            compareDanger() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[`${daterangeKey(this.compareDaterange)}-${this.reportRestaurantsKey}`];
                }
            },
            compareTotal() {
                return this.compareDanger?.total;
            },
            compareRestaurants() {
                return this.compareDanger?.restaurants;
            },
            compareTotalEvents() {
                return this.compareDanger?.total?.events;
            },
            reportAndCompareTotalEvents() {
                return Object.entries({
                    ...Object.fromEntries(this.reportTotalEvents?.map(({ id, event: title }) => [id, title])),
                    ...Object.fromEntries(this.compareTotalEvents?.map(({ id, event: title }) => [id, title]) ?? [])
                })?.map(([id, title]) => ({ id, title }));
            },
            popperOptions() {
                return {
                    modifiers: {
                        offset: { offset: '0,10px' }
                    }
                };
            }
        },
        methods: {
            ...mapMutations([
                "toggleRestaurantsFilter",
                "setReportDaterange",
                "setDangerParameter",
                "setDangerRestaurantSorting",
            ]),
            ...mapActions([
                "requestDangers"
            ]),
            setSorting(group, field) {
                this.setDangerRestaurantSorting({ group, field });
            },
            findRestaurant(danger, restaurantId) {
                return danger?.restaurants?.find(({ id }) => id === restaurantId);
            },
            findEventValue(events, eventId, eventField) {
                return events?.find(({ id }) => id === eventId)?.[eventField];
            },
            showReport() {
                this.status = "success";
            },
            loadDangers() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestDangers({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            daterangeText
        },
        created() {
            this.toggleRestaurantsFilter(true);

            if (this.reportRestaurants.length === 0) {
                this.status = "no-restaurants";
            } else if (this.requiredDangers?.every(Boolean)) {
                this.showReport();
            } else {
                this.loadDangers();
            }
        },
        watch: {
            requiredDangers(requiredDangers) {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredDangers.every(Boolean)) {
                    this.loadDangers();
                } else {
                    this.showReport();
                }
            }
        }
    }
</script>

<style lang="scss">
    .danger-total__settings {
        margin-bottom: 15px;
    }
    .danger-total__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .danger-total__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .danger-total__select {
        min-width: 240px;
        padding: 4px 8px;
        margin-top: 15px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
    .danger-total__box {
        padding: 16px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $gray-line;
        }
        &::-webkit-scrollbar-track {
            border-width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $text-gray-secondary;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .danger-total__table {
        min-width: 100%;
    }
    .danger-total__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .danger-total__table-column {
        &_title {
            min-width: 240px;
        }

        &_danger_count {
            min-width: 100px;
        }

        &_sum {
            min-width: 100px;
        }

        &_dangers_per_1000_orders {
            min-width: 100px;
        }

        &_orders_per_danger {
            min-width: 100px;
        }
    }
    .danger-total__table-row {
        &_body {
            .danger-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .danger-total__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .danger-total__table-cell {
        font-size: 20px;
        padding: 14px 12px;

        &_head {
        }

        &_head-bottom {
            padding: 8px 10px;
            font-size: 12px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
            text-align: left;
        }

        &_vat {
            vertical-align: top;
        }

        &_offset-left {
            padding-left: 24px;
        }

        &_offset-right {
            padding-right: 24px;
        }
    }
    .danger-total__table-cell_tac {
        text-align: center;
    }
    .danger-total__table-cell_tar {
        text-align: right;
    }
    .danger-total__table-cell_tal {
        text-align: left;
    }
    .danger-total__cell-content {
        display: grid;

        &_columns {
            grid-auto-flow: column;
            align-items: center;

            &.danger-total__cell-content_left {
                justify-content: start;
            }

            &.danger-total__cell-content_right {
                justify-content: end;
                text-align: right;
            }
        }

        &_rows {
            grid-auto-flow: row;

            &.danger-total__cell-content_left {
                justify-items: start;
            }

            &.danger-total__cell-content_right {
                justify-items: end;
            }
        }
    }
    .danger-total__table-value {
        display: block;
        line-height: 1;

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }

        &_tooltip {
            cursor: pointer;
            border-bottom: 1px dotted;
        }
    }
    .danger-total__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .danger-total__table-text {
        flex-grow: 1;
        vertical-align: middle;
    }
    .danger-total__tooltip {
        max-width: 300px;
        padding: 5px 10px;
        text-align: left;
        background-color: #fff;
    }
</style>
